/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.less';
import '@fortawesome/fontawesome-pro/css/all.min.css';

function _() {
    import('./images/logo.svg');
    import('./images/default.svg');
}

document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    let headerUserDropdownToggle = e.target.closest('.header-user-dropdown-toggle');
    if (headerUserDropdownToggle)
    {
        let headerUserDropdown = headerUserDropdownToggle.closest('.header-user-dropdown');
        headerUserDropdown.classList.toggle('active');
    }
});
